<template>
  <div class="roast-comp">
    <div class="send_tc" @click="handleRoast">
      <svg-icon icon="icon_send"></svg-icon>
      <span>我要吐槽</span>
    </div>

    <div class="item" v-for="(item, index) in pageData.list" :key="item.id">
      <router-link :to="{
        path:`/comment`,
        query:{id: item.id }
      }">
        <div class="avator">
          <div class="avator-group">
            <img
              class="head"
              :src="item.userAvatar"
              v-if="item.userAvatar && item.userAvatar != 'null'"
            />
            <svg-icon icon="icon_avator" v-else></svg-icon>
            <svg-icon
              icon="icon_male"
              class="sex sex-male"
              v-if="item.userSex == 1"
            ></svg-icon>
            <svg-icon
              icon="icon_female"
              class="sex sex-female"
              v-if="item.userSex != 1"
            ></svg-icon>
          </div>
          <span>
            <div class="nickname">{{ item.userNickname }}</div>
            <!-- <div style="height: 14px; overflow: hidden">
            <el-rate :value="item.userStarLevel || 0" disabled></el-rate>
          </div>
          <div style="font-size: 12px; color: #ccc">
            来自：{{ item.comeFrom }}
          </div> -->
          </span>
        </div>
        <div class="comment">
          <div class="content">
            {{ item.content }}
          </div>
        </div>
      </router-link>
    </div>
    <!-- <div class="more" v-loading="loading">
      <span v-if="ended">到底了</span>
      <a @click="handleMore" v-else>载入更多</a>
    </div> -->
  </div>
</template>
<script setup>
import { ref, reactive, defineAsyncComponent, getCurrentInstance } from 'vue'
import qs from 'qs'
import { useRoute } from 'vue-router'
import { Options, Vue } from 'vue-class-component'
import InnerSearch from '@/components/InnerSearch.vue'
import ItemProject from '@/components/ItemProject.vue'
import QuickPublishProject from '@/components/QuickPublishProject.vue'
import BlockSuggestProject from '@/components/BlockSuggestProject.vue'
import { Api } from '@/services/http'
import { showRoast } from '@/libs/comp'

const route = useRoute()
const loading = ref(false)
const ended = ref(false)
const pageData = reactive({
  total: 0,
  list: [],
})
const pagination = reactive({
  current: 1,
  size: 10,
})
const instance = getCurrentInstance()
const dialogRef = ref(null)
if (route.query.keyword) {
  pagination.searchStr = route.query.keyword
}
const mounted = async () => {
  loadPage()
}

const handlePage = page => {
  loadPage(page)
}
const handleRoast = () => {
  const { proxy, ctx } = instance
  showRoast(proxy, dialogRef)
}
const handleMore = () => {
  pagination.current += 1
  loadPage(pagination.current)
}
const loadPage = async (page = 1) => {
  loading.value = true
  pagination.current = page
  let data = await Api.post('/client-api/roast/list', qs.stringify(pagination))
  if (data) {
    let list = (data.list || []).filter(item => item.auditStatus == 2)
    if (list && !list.length) {
      ended.value = true
    }
    pageData.list = pageData.list.concat(list)
    pageData.total = data.total
  }
  loading.value = false
}
mounted()
</script>
<style lang="scss" scoped>
.roast-comp {
  margin: 0;
  width: 100%;
  font-size: 14px;
  position: relative;
  padding: 10px;
  box-sizing: border-box;
  .item {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 20px;
    border: 1px solid #ededed;
    padding: 10px;
    border-radius: 10px;
    a{
      display: flex;
      &:hover{
        color:#2c3e50
      }
    }
    &:hover{
      border-color: #0093ef;
    }
  }
  .content {
    background: white;
    width: 100%;
    padding-left: 10px;
    min-height: 50px;
    flex: 1;
    box-sizing: border-box;
    position: relative;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; /* 显示的行数 */
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.5em; /* 每行的高度 */
    max-height: 3em; /* line-height * 行数 */

    &::before {
      content: ' ';
      display: block;
      position: absolute;
      border-top: 5px solid transparent;
      border-bottom: 11px solid transparent;
      border-right: 11px solid #dedede;
      height: 0px;
      left: -15px;
      top: 5px;
    }
    &::after {
      content: ' ';
      display: block;
      position: absolute;
      border-top: 5px solid transparent;
      border-bottom: 11px solid transparent;
      border-right: 11px solid white;
      height: 0px;
      left: -14px;
      top: 5px;
    }

    .images .el-image {
      width: 25%;
    }
  }
}
.avator-group {
  position: relative;
  width: 50px;
  flex-basis: 50px;
  display: flex;
  svg.sex {
    position: absolute;
    top: 0;
    left: 29px;
    width: 10px;
    height: 10px;
    border: 1px solid #ccc;
    border-radius: 100%;
    padding: 2px;
    background: white;
  }
  svg.sex-female {
    color: #ff702f;
    background-color: #f7d8c9;
  }
  svg.sex-male {
    color: #0093ef;
    background-color: #fff3ed;
  }
}
.head {
  width: 40px;
  height: 40px;
  flex-basis: 40px;
  margin-right: 10px;
  border-radius: 100%;
}
.avator {
  width: 147px;
  text-align: left;
  display: flex;
  flex-basis: 147px;
  overflow: hidden;
  .nickname {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .svg-icon {
    width: 40px;
    height: 40px;
    flex-basis: 40px;
    margin-right: 10px;
  }
  ::v-deep .el-rate {
    height: 14px;
    .el-rate__icon {
      margin-right: 0;
      margin-left: 1px;
    }
    .el-icon {
      width: 12px;
    }
    .el-icon svg {
      width: 12px;
      height: 12px;
    }
  }
}
.more {
  padding: 10px;
  a {
    border: 1px solid #ccc;
    padding: 5px 10px;
    border-radius: 9px;
    display: inline-block;
  }
}
.comment {
  width: 100%;
  text-align: left;
  white-space: pre-wrap;
  .content {
    font-size: 16px;
  }
}

.send_tc {
  font-size: 17px;
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  padding: 7px;
  border-radius: 3px;
  position: fixed;
  bottom: 100px;
  right: 16px;
  background: white;
  cursor: pointer;
  z-index: 1;
  &:hover {
    border-color: #0093ef;
    color: #0093ef;
    .svg-icon {
      color: #0093ef;
    }
  }
  .svg-icon {
    width: 30px;
    height: 30px;
  }
}
</style>
